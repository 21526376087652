import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PreviewCompatibleImage from './PreviewCompatibleImage';
import Img from "gatsby-image"


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: "100%",
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        // transform: 'translateZ(0)',
    },
    imageListItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",


    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    image: {
        // objectFit: "contain",
        width: "100%",
        height: "auto",

    }
}));



const ImageListLine = ({ itemData }) => {
    console.log('itemData', itemData)
    const classes = useStyles();

    return (
        <ImageList className={classes.imageList} cols={6}>
            {itemData.map((item, i) => (
                <ImageListItem key={i} classes={{ item: classes.imageListItem, imgFullHeight: classes.image, imgFullWidth: classes.image }}>
                    {/* <PreviewCompatibleImage imageInfo={item} classes={classes.image} /> */}
                    {/* <img src={item.image.childImageSharp.fluid.src} alt={item.title} className={classes.image} /> */}
                    <Img fluid={item.image.childImageSharp.fluid} className={classes.image} />
                    {/* 
                    <ImageListItemBar
                        title={item.title}
                        classes={{
                            root: classes.titleBar,
                            title: classes.title,
                        }}
                    actionIcon={
                        <IconButton aria-label={`star ${item.title}`}>
                            <StarBorderIcon className={classes.title} />
                        </IconButton>
                    }
                    /> */}
                </ImageListItem>
            ))}
        </ImageList>
    );
}

export default ImageListLine;