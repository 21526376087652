
const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    // maxWidth: 400,
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary,
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
  },
  container: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(8),
    }
  },

  link: {
    textDecoration: "none",
    whiteSpace: "nowrap",
  },
  description: {
    "& p": {
      fontSize: "1rem",
      textAlign: "center",
    },
  },
  avatar: {
    width: 40,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      width: 30,
      height: 30,
    },
  },
});
export default styles;
