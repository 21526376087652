import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import useMediaQuery from "@material-ui/core/useMediaQuery";




import { Link, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import styles from "./styles";
import Cards from "../Cards";
import Rating from '@material-ui/lab/Rating';

import HTMLContent from "../Content";
import { graphql } from "gatsby";
import PreviewCompatibleImage from "../PreviewCompatibleImage";
import { OutboundLink } from "../OutboundLink";



const StartCards = ({ classes, data }) => {
  const { reviews } = data.allMarkdownRemark.edges[0].node.frontmatter
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Grid container
      justifyContent="center"
      spacing={2}
      alignContent="center"
      className={classes.container}

      alignItems="center">

      {reviews && reviews.map((review, i) => (

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-around"
          item xs={3}
          md={4}
          key={i}
        >
          <Avatar alt={review.name} src={review.logo.childImageSharp.fixed.src} className={classes.avatar} />
          <OutboundLink
            className={classes.link}

            target="_blank"
            href={review.link}>
            {review.name} </OutboundLink>

          <Rating size={"small"} name="half-rating-read" defaultValue={review.rating} precision={0.5} readOnly />


        </Grid>

      ))}
      {/* (start, i) =>
          i < showAll && (
            <Grid
              item xs={12} md={6}
              container
              justifyContent="center"
              alignContent="center"
              alignItems="stretch"


              key={`${start.course}-${start.date}`}>

              <Card className={classes.card}>
                <CardHeader
                  title={start.fdate}
                  subheader={start.subheader}
                  titleTypographyProps={{
                    fontSize: "2.5rem",
                    align: "center",
                  }}
                  subheaderTypographyProps={{ align: "center" }}
                  className={classes.cardHeader}
                  avatar={<Avatar alt="" src={start.chip.childImageSharp.fixed.src} className={classes.avatar} />}
                />
                <CardContent className={classes.cardContent}>
                  <Typography component="h4" variant="h4" color="textPrimary">
                    {start.title}
                  </Typography>
                  <HTMLContent
                    className={classes.description}
                    content={start.description.childMarkdownRemark.html}
                  />
                </CardContent>
                <CardActions disableSpacing className={classes.cardActions}>
                  <Link
                    className={classes.link}
                    to={`/application?course=${start.course}&date=${start.date}`}
                  >
                    <Button variant="contained">
                      Apply for this course
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          )
      )}
      {all && nextStarts.length > 2 && (
        <Grid item xs={12} className={classes.cardContent}>
          {showAll !== nextStarts.length ? (
            <Button
              onClick={() => setShowAll(nextStarts.length)}
              color="primary"
            >
              Show more
            </Button>
          ) : (
            <Button onClick={() => setShowAll(2)} color="inherit">
              Show less
            </Button>
          )}
        </Grid>
      )} */}
    </Grid>
  );
};
StartCards.propTypes = {
  location: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  data: PropTypes.shape({}),
};
withStyles(styles)(StartCards);

const RatingsQuery = ({ location, classes, all, course }) => (
  <StaticQuery
    query={graphql`
      query RatingsQuery {
        allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "reviews" } } }
        ) {
          edges {
            node {
              frontmatter {
                reviews {
                  name
                  rating
                  link               
                  logo {
                    childImageSharp {
                      fixed {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <StartCards data={data} location={location} classes={classes} />
    )}
  />
);
export default withStyles(styles)(RatingsQuery);