import React, { useState } from "react";
import { Grid, Typography, } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import Hidden from "@material-ui/core/Hidden";
import video from "../../../public/assets/landing_video.mp4";
import CabButton from "../CabButton";

import styles from "./styles";
import { TweenMax, TimelineMax, Power2 } from "gsap/all";

const RenderGradient = ({ title, subheading, location, classes }) => {
  const [videoRef, setVideoRef] = useState(null);
  let tl = new TimelineMax();
  const videoFaddeIn = () => {
    tl.fromTo(
      videoRef,
      2,
      { width: "0%", ease: Power2 },
      { width: "50%", ease: Power2 }
    );
    TweenMax.to(videoRef, 3, {
      display: "block",
    });
  };

  return (
    <div className={classes.gradient}>
      {/* <Grid container className={classes.container}> 
      <div className={classes.contentContainer}>*/}
      <RenderHeroContent
        location={location}
        title={title}
        subheading={subheading}
        classes={classes}

      />
      {/* </div> */}
      <Hidden mdDown>
        <div ref={(el) => setVideoRef(el)} className={classes.videoContainer}>
          <video
            muted
            autoPlay
            // src="https://res.cloudinary.com/ds3w3iwbk/video/upload/f_auto:video,q_auto/duis8jeascby3ebpwpiy"
            src={video}
            preload={"auto"}
            type={"video/mp4"}
            className={classes.video}
            onLoadedData={videoFaddeIn}
          />
        </div>
        {/* <div className={classes.rightContainer}>
            <Typography className={classes.txt} component="h3" variant="h3" gutterBottom>BERLIN</Typography>
            <Typography className={classes.txt2} component="h3" variant="h3" gutterBottom>CALLING</Typography>
            <Typography className={classes.txt3} component="h3" variant="h3" gutterBottom>CODING</Typography>
          </div> */}
      </Hidden>
      {/* </Grid> */}
    </div>
  );
};
RenderGradient.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  classes: PropTypes.shape({}),
};

const RenderHeroContent = ({ title, subheading, location, classes }) => {
  return (
    <div
      className={classes.content}
    >
      {/* <Typography component="h1" variant="h1" gutterBottom >{title}</Typography> */}
      < Typography className={classes.header} component="h1" variant="h1" gutterBottom >
        <span className={classes.underline}>On-Campus Community </span> <br />of future Developers
      </Typography >

      <Typography component="h2" variant="h2" className={classes.subheader} >
        {subheading}
      </Typography>
      <Grid container
        spacing={2}
        alignContent="center"
        justifyContent="center"
      // className={classes.buttonContainer}
      >
        <Grid item xs={12} sm={6}  >
          <CabButton to={`/courses`}>
            Our Courses
          </CabButton>

        </Grid>

      </Grid>
    </div >
  );
};
RenderHeroContent.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  classes: PropTypes.shape({}),
};

const HeroLanding = ({ title, subheading, location, classes }) => {

  return (
    <>
      <RenderGradient
        title={title}
        subheading={subheading}
        location={location}
        classes={classes}
      // style={{ position: "absolute" }}
      />

      {/* <div className={classes.waveDiv}>

        <HeroSVG className={classes.wave} />
      </div> */}
    </>


  );
};
// const HeroLanding = ({ title, subheading, location, classes }) => {
//   return (
//     <React.Fragment>
//       <RenderGradient
//         title={title}
//         subheading={subheading}
//         location={location}
//         classes={classes}
//       />
//       <div className={classes.waveDiv}>
//         {/* <Wave2023 /> */}
//         <HeroSVG className={classes.wave} />
//       </div>
//     </React.Fragment>
//   );
// };
HeroLanding.propTypes = {
  title: PropTypes.string,
  subheading: PropTypes.string,
  classes: PropTypes.shape({}),
};
export default withStyles(styles)(HeroLanding);
