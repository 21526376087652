const styles = (theme) => ({
  container: {
    //   marginTop: theme.spacing(10),
    //   [theme.breakpoints.down("md")]: {
    //     marginTop: theme.spacing(4),
    //   },
    //   [theme.breakpoints.down("sm")]: {
    //     marginTop: theme.spacing(6),
    //   },
    gap: theme.spacing(6),
  },
});
export default styles;
