import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import { Container, Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import LandingHeaderSection from "../components/LandingHeaderSection";
import LandingMainPitchSection from "../components/LandingMainPitchSection";
import HeaderSection from "../components/HeaderSection";
import Cards from "../components/Cards";
import Layout from "../components/Layout";
import SectionDecoration from "../components/SectionDecoration";
import SEO from "../components/SEO";
import ContentCards from "../components/ContentCardsCampus";
import Pricing from "../components/Pricing";
import ImageListLine from "../components/ImageListLine";


const IndexPageTemplate = ({
  heading,
  subheading,
  mainpitch,
  companies,
  main,
  alumni,
  included,
  location,
}) => {
  const theme = useTheme();

  return (
    <>
      <Container maxWidth="lg" >


        <LandingHeaderSection
          title={heading}
          subheading={subheading}
          location={location}
        />
        <LandingMainPitchSection mainpitch={mainpitch} location={location} main={main}
        />
      </Container>
      <SectionDecoration
        color={theme.palette.common.white}
        bgColor={theme.palette.primary.main}
      >

        <HeaderSection
          header={included.title}
          subheader={included.description}
        />
        <ContentCards content={included} />
        {/* <Cards location={location} cards={included.blurbs} variant="overlay" /> */}


      </SectionDecoration>
      <Container maxWidth="lg" >
        <Pricing location={location} small={false} />
        <Grid container justifyContent="center" spacing={4} alignItems="center">
          <HeaderSection header={companies.title} location={location} />
          <ImageListLine itemData={companies.blurbs} />
          <HeaderSection header={"Testimonials"} location={location} subheader={"Check the testimonials and projects from some of our alumni"} />
          <Grid item xs={12}>
            <Cards location={location} cards={alumni.blurbs} primary variant="longOverlay" />
          </Grid>
          <Grid item align="center" xs={12}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              component={Link}
              to="/alumni"
            >
              More testimonials      </Button>
          </Grid>
        </Grid>

        {/* <LandingBottomSection alumni={alumni} career={included} location={location} /> */}
      </Container>
    </>
  );
};
IndexPageTemplate.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  career: PropTypes.object,
  main: PropTypes.object,
  alumni: PropTypes.object,
  included: PropTypes.object,
  companies: PropTypes.object,
};

const IndexPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges: posts } = data.allMarkdownRemark;
  const cards = posts.map(({ node: post }) => {
    return {
      title: post.frontmatter.title,
      text: post.frontmatter.date,
      image: post.frontmatter.frontImage,
      description: post.frontmatter.description,
      cohort: post.frontmatter.cohort,
      course: post.frontmatter.course,
      quote: post.frontmatter.quote,
      date: post.frontmatter.date,
      link: post.fields.slug,
      author: post.frontmatter.author,

    }
  })
  return (
    <Layout location={location}>
      <SEO title={frontmatter.heading} location={location} />
      <IndexPageTemplate
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        included={frontmatter.included}
        companies={frontmatter.companies}
        location={location}
        main={frontmatter.courses}
        alumni={{ blurbs: cards }}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heading
        subheading
        mainpitch {
          title
          description
           blurbs {
            image {
              childImageSharp {
                 fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }              
              }
            }
            title
            text

          }
        }
        included {
          title
          description
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }              
              }
            }
            title
            text  
          }
        }
        companies {
          title
          description
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid
                }    
                  
                 fixed(width: 200) {
                  ...GatsbyImageSharpFixed
                }                
                        
              }
            }
            title  
          }
        }
        courses {
          title
          description
          blurbs {
            image {
               childImageSharp {
                  fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }              
                }
            }
            title
            text
            link 
          }
        }
      }  
    }
    allMarkdownRemark(
      limit: 3
       sort: { order: DESC, fields: [frontmatter___date] }
    filter: {frontmatter: {templateKey: {eq: "alumni-post"}, featuredpost: {eq: true}}}
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            cohort
            course
            date(formatString: "MMMM YYYY")
            description
            quote
            featuredpost
            archived
            frontImage {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    } 
  }
`;
