import { colors } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import zIndex from "@material-ui/core/styles/zIndex";
const styles = (theme) => ({
  gradient: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // [theme.breakpoints.up("sm")]: {
    paddingBottom: theme.spacing(2),
    // },
    // paddingBottom: theme.spacing(4),
    flex: 1,
    backgroundColor: "transparent",
    minHeight: "400px",
    // backgroundColor: theme.palette.primary.main,
    // backgroundSize: "cover",
    // overflow: "hidden",

    // [theme.breakpoints.down("md")]: {
    //   minHeight: "25vh",
    //   // backgroundColor: "transparent",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   minHeight: "35vh",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   minHeight: "40vh",
    // },


  },
  container: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "flex-start",
    alignItems: "center",
    borderRadius: "25px",
    color: theme.palette ? theme.palette.common.white : "white",
  },
  contentContainer: {
    alignSelf: "stretch",
    width: "50%",
    padding: 0,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    marginBottom: theme.spacing(4),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    // [theme.breakpoints.down("md")]: {
    //   marginRight: theme.spacing(6),
    //   marginLeft: theme.spacing(6),
    // },

  },
  content: {
    // marginBottom: theme.spacing(4),
    alignSelf: "stretch",
    zIndex: 99,
    marginBottom: theme.spacing(4),



    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing(4),
    textAlign: "center",
    color: theme.palette ? theme.palette.common.white : "white",

    [theme.breakpoints.down("md")]: {
      marginBottom: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      // alignItems: "center",
      // justifyContent: "center",
      // textAlign: "center",
    },
  },
  header: {
    fontFamily: "Lovelo",
    textAlign: "right",
    whiteSpace: "nowrap",
    paddingRight: theme.spacing(4),
    fontSize: "1.2rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      whiteSpace: "normal",
    }

  },
  roundContainer: {
    // backgroundColor: "#e0e0e0",
    whiteSpace: "nowrap",
    borderRadius: "50%",
    // color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(10),
    justifyContent: "space-evenly",
    alignItems: "center",
    width: 300,
    height: 300,
    // [theme.breakpoints.down("md")]: {
    //   width: 200,
    //   height: 200,
    // },
    [theme.breakpoints.down("sm")]: {
      width: 100,
      height: 100,
    },
  },
  subheader: {
    fontSize: "1rem",
    fontWeight: 400,

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  },

  underline: {
    fontFamily: "Lovelo",
    fontSize: "2rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3.5rem",
    },


    color: theme.palette.secondary.light
  },
  button: {
    alignSelf: "flex-end",
  },
  // buttonContainer: { display: "flex", justifyContent: "center" },
  buttonClasses: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      // color: theme.palette.secondary.main,
      // animationName: "$buttonAnimation",
      // animationFillMode: "forwards",
      // animationDuration: "0.3s",
    },

    width: 200,
    height: 40,
    fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.up("sm")]: {
      width: 500,
      height: 60,
      fontSize: 30,
    },
  },
  waveDiv: {
    position: "absolute",
    left: 0,
    width: "100%",
  },
  applyButton: {
    fontFamily: "Lovelo",

    alignSelf: "center",
    marginRight: theme.spacing(2),

    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    width: "100%",
    padding: theme.spacing(1),

    // fontSize: 15,
    fontWeight: 400,
    [theme.breakpoints.up("sm")]: {
      height: 60,
      fontSize: 30,
    },
  },
  coursesButton: {
    fontFamily: "Lovelo",
    alignSelf: "center",
    // marginRight: theme.spacing(2),

    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
    },
    width: "100%",
    padding: theme.spacing(1),

    fontSize: "0.8rem",
    // fontWeight: 400,
    [theme.breakpoints.up("sm")]: {
      // height: 60,
      // fontSize: "1.5rem",

      // fontSize: 30,
    },
  },
  wave: {
    width: "100%",
    position: "absolute",
    bottom: -2,
    left: 0,
    color: theme.palette ? theme.palette.common.white : "white",
    fill: "currentcolor",
    pointerEvents: "none",
  },
  videoContainer: {
    position: "relative",
    display: "none",
    borderRadius: 10,
    "&:before": {
      content: "",
      position: "absolute",
      background: alpha(theme.palette.primary.main, 0.1),
      borderRadius: "5px",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  rightContainer: {
    position: "relative",
    // display: "none",
    borderRadius: 10,
    "&:before": {
      content: "",
      position: "absolute",
      // background: alpha(theme.palette.primary.main, 0.1),
      borderRadius: "5px",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  },
  video: {
    width: `100%`,
    borderRadius: 10,
  },
  txt: {
    width: `100%`,
    letterSpacing: 10,
    fontFamily: "Alenia",
    textAlign: "center",
    textJustify: "center",
    fontSize: 100,
    fontWeight: 400,
    lineHeight: 1,
    margin: 0,
    padding: 0,
    color: theme.palette.common.white,
  },
  txt3: {
    width: `100%`,
    letterSpacing: 8,
    fontFamily: "Alenia",
    textAlign: "center",
    textJustify: "center",
    fontSize: 100,
    fontWeight: 400,
    lineHeight: 1,
    margin: 0,
    padding: 0,
    color: theme.palette.common.white,
  },
  txt2: {
    width: `100%`,
    textDecoration: "line-through",
    fontFamily: "Alenia",
    fontSize: 100,
    fontWeight: 400,
    lineHeight: 1,
    margin: 0,
    padding: 0,
    color: theme.palette.common.white,
  },
  img: {
    alignSelf: "flex-end",
    height: "100%",
  },
});
export default styles;
