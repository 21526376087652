import React from "react";
import { Grid, Button } from "@material-ui/core";
import HeaderSection from "../HeaderSection";
import Cards from "../Cards";
import BookingModal from "../BookingModal";
import StartCards from "../StartCards";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import styles from "./styles";
import Ratings from "../Ratings";
import { Link } from "gatsby";



const LandingMainPitchSection = ({ mainpitch, main, location, classes }) => {
  return (
    <Grid container
      // spacing={10}
      direction="column"
      alignItems="center"
      justifyContent="center"
      maxwidth="lg"
      className={classes.container}
    >
      <Grid container
        justifyContent="center"
        alignItems="center"
        spacing={2}>

        <Grid item xs={12} md={8}>
          <HeaderSection header={mainpitch.title} location={location} subheader={mainpitch.description} />
        </Grid>
        <Grid item xs={12} md={3}>
          <Ratings />
        </Grid>
      </Grid>
      <Cards location={location} cards={mainpitch.blurbs} variant="overlay" />
      <HeaderSection header={main.title} location={location} subheader={main.description} />
      {/* <BookingModal variant="contained" color="primary" /> */}
      <Button
        variant="contained"
        component={Link}
        color="primary"
        to="/advisors"
      >Talk to our advisors</Button>
      <Cards location={location} cards={main.blurbs} primary={true} variant="overlay" />

      <HeaderSection header="Next courses" />
      <StartCards location={location} all={true} jsonLd course="all" />


    </Grid>
  );
};
LandingMainPitchSection.propTypes = {
  mainpitch: PropTypes.shape({}),
  main: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};
export default withStyles(styles)(LandingMainPitchSection);
